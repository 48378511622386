.book-container {
  width: 100%;
  padding: 50px 10% 100px 10%;
  display: flex;
  justify-content: space-between;
}

.book-title {
  padding: 20px 50px;
  width: 80%;
  box-shadow: 0px 0px 20px rgba(71, 68, 72, 0.15);
  /* border: 2px solid #8F8DFF; */
  background-color: white;
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 75px;
}

.book-avatar-container {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}

.book-avatar-container > h3, .book-avatar-container > i {
  z-index: 4;
  color: white;
}

.book-avatar {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: brightness(75%);
}

.book-count {
  border-radius: 50px;
  height: 28px;
  width: 28px;
  color: #474448;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 11px;
  margin-right: 10px;
}

.book-count-active {
  background-color: #8f8dff;
  color: white;
  border: 1px solid #8f8dff
}

.booking-week-info > *:first-child {
  margin: 0;
  margin-right: 20px
}

.book-title-opened {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-title-opened > img {
  height: 5vh;
  border-radius: 50px;
  margin-right: 20px;
}

.date-picker-input {
  height: 44px !important;
}

/* .date-picker-date-group > *:first-child {
  width: 55% !important;
}

.date-picker-date-group > *:last-child {
  width: 45% !important;
} */

.react-google-flight-datepicker {
  width: 40%;
  max-width: 800px;
}

.book-calendar {
  padding: 0 !important;
  border-radius: 0 !important;

}

.dialog-date-picker {
  border-radius: 0 !important
}

.react-google-flight-datepicker .date-picker-input, .react-google-flight-datepicker .date, .react-google-flight-datepicker .date.is-focus::after {
  border-radius: 0 !important
}

.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: #8F8DFF !important
}

.react-google-flight-datepicker .day.hovered {
  background-color: #e4e4f7 !important
}

.react-google-flight-datepicker .day.selected.hovered.end, .react-google-flight-datepicker .day.hovered.end {
  background: linear-gradient(90deg, #e4e4f7 50%, #fff 50%) !important
}

.react-google-flight-datepicker .day.selected.hovered {
  background: linear-gradient(90deg, #fff 50%, #e4e4f7 50%) !important
}

.react-google-flight-datepicker .day:hover::after {
  border: 2px solid #8F8DFF !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
  background: #8F8DFF !important;
  border-radius: 0 !important;
}

.date-placeholder {
  font-size: 0.9rem !important;
  line-height: normal !important;
  letter-spacing: 0.2px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.book-hours-container, .book-price-container {
  height: 66px;
  width: 50%;
  outline: none;
  border: 1px solid lightgray;
  border-left: none;
  background-color: white;
  padding: 0 13px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.book-container-content {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
}

.book-hours-container {
  width: 50%;
  border-left: 1px solid lightgray;
  border-right: none;
}

.book-container-content > svg {
  margin-right: 10px;
  color: #8F8DFF;
  font-size: 20px;
}

.book-price, .book-hours {

  /* font-weight: bold; */
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 25%;
  flex-grow: 1;

  color: #3c4043;

  outline: none;
  border: none;
}

.book-total {
  text-align: right;
  position: relative;
  margin-top: 100px;
}

.book-total > * {
  margin: 0;
}

.button-container {
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: flex-end
}

.input-line {
  height: 45px;
  width: 1px;
  background-color: lightgray;
}

.half-container > h1 {
  font-size: 44px;
  margin: 0;
}

.pricing-container {
  width: 30%;
  min-width: 400px;
  background-color: #f5f5f5;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.15);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0;
  align-self: flex-start;
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  /* position: fixed;
  right: 10%; */
}

.half-container-book {
  width: 40%;
  position: relative;
}

.half-container-book-content {
  margin-top: 50px;
}

.booking-category-select-container, .booking-category-textarea-container {
  height: 66px;
  outline: none;
  border: 1px solid lightgray;
  border-bottom: none;
  background-color: white;
  padding: 0 30px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.booking-category-textarea-container {
  height: auto;
  border-bottom: 1px solid lightgray;
  padding: 20px 30px;
}

.booking-category-select, .booking-category-input {
  font-family: "Source Sans Pro", sans-serif;
  outline: none;
  border: none;
  background-color: white;
  color: #474448;
  width: 100%;
  margin-top: 2px;
  margin-left: -4px;
}

.booking-category-input {
  margin-left: 0
}

.booking-category-input::placeholder {
  color: gray;
}

.book-textarea {
  border: none;
  outline: none;
  height: 150px;
  resize: none;
  color: #474448;
  font-family: "Source Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  margin-left: -3px;
  margin-top: 2px;
}

.book-price::placeholder, .book-textarea::placeholder, .book-hours::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.56);
}

.profile-item:first-of-type {
  padding-bottom: 0;
}

.profile-item:last-of-type {
  padding-bottom: 150px;
}

.profile-item {
  padding: 75px 10%;
}

.my-profile-img {
  height: 125px;
  width: 125px;
  border-radius: 75px;
  margin-right: 50px;
}

.edit-profile-img {
  position: relative;
  margin-right: 50px;
}

.edit-profile-img > img {
  cursor: pointer;
  margin-right: 0;
}

.edit-profile-img:hover img {
  filter: brightness(0.5);
}

.edit-profile-txt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  visibility: hidden;
  z-index: 10;
  pointer-events: none;
}

.edit-profile-img:hover .edit-profile-txt {
  visibility: visible;
}

.self-view {
  padding: 75px 10%;
  background-color: #474448;
}

.experience-section {
  justify-content: space-between;
}

.experience {
  width: 45%;
}

.experience-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.experience-item {
  margin-bottom: 50px;
  box-shadow: 0px 10px 20px rgba(71, 68, 72, 0.15);
  position: relative;
  background-color: white;
  padding: 25px 30px;
  align-self: flex-start;
  color: #474448;
}

.experience-container > *:last-child {
  margin-bottom: 0px;
}

.experience-label {
  border-radius: 50px;
  position: absolute;
  left: 15px;
  top: -15px;
  height: 30px;
  width: 30px;
  background-color: #8f8dff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 10px;
}

.experience-line {
  height: 80%;
  width: 3px;
  background-color: #8f8dff;
  position: absolute;
  left: 28px;
  top: 0px;
  z-index: 0;
}

.section-header {
  color: #474448;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.profile-skills,
.profile-works,
.profile-awards {
  margin-top: 50px;
}

.skill-static {
  padding: 5px 40px;
  margin: 5px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-name-static {
  display: inline-flex;
  z-index: 5;
}

.works-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.works-item {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.1);
  background-color: white;
  padding: 50px;
  width: 45%;
  min-width: 300px;
  margin: 40px 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.works-item:hover {
  box-shadow: 0px 12px 32px rgba(71, 68, 72, 0.15);
  transform: scale(1.01) translateY(-1%);
  transition: all 0.2s ease-in;
}

.column-works-item {
  width: 100%;
}

.works-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
}

.works-section {
  margin-top: 25px;
  position: relative;
  /* display: flex;
  flex-direction: column; */
}

.works-section-item {
  /* align-self: flex-start; */
}

.works-section-item-link:hover {
  color: #8f8dff;
}

.works-col-wrapper {
  max-width: 45%;
  width: 45%;
}

.works-header-img {
  height: 36px;
  margin-right: 15px;
}

.github {
  color: #24292e;
}

.medium {
  color: #018f69;
}

.instagram {
  color: #dd2a7b;
}

.gray {
  color: #474448;
}

.works-section-header {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 20px;
  color: #474448;
}

.works-section-item {
  margin-top: 15px;
  color: #474448;
}

.works-laptop {
  width: 100%;
  z-index: 2;
  position: relative;
  pointer-events: none;
}

.works-laptop-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  z-index: 1;
}

.personal-website {
  width: 100%;
  position: relative;
  cursor: pointer;
  pointer-events: all;
}

.medium-img {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
  margin: 10px 0;
}

.insta-propic {
  height: 30px;
  border-radius: 15px;
}

.insta-logo {
  width: 50%;
}

.insta-handle {
  font-size: 12px;
  margin-left: 15px;
  color: #474448;
}

.insta-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.insta-grid div::before {
  content: "";
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}

.insta-img {
  width: 100%;
  background-size: cover;
  background-position: center;
  /* height: 100%; */
  /* background-color: #f5f5f5; */
  /* margin-bottom: 100%; */
}

.work-iframe {
  width: 75%;
  border: 4px solid #474448;
  height: 350px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {

  .book-container {
    flex-direction: column-reverse;
    padding-top: 0;
  }

  .pricing-container {
    position: relative;
  }

  .booking-category-select {
    font-size: 14px;
    margin-left: 0;
  }

  .booking-week-info > *:first-child {
    margin: 0;
    margin-bottom: 10px;
  }

  .half-container-book {
    width: 100%;
    position: static;
  }

  .book-container-inner {
    overflow-y: scroll;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .half-container-book {
    margin-bottom: 50px;
  }

  .react-google-flight-datepicker .icon-calendar.mobile {
    display: none !important;
  }

  .react-google-flight-datepicker .date-picker-date-group {
    flex-direction: column;
  }

  .react-google-flight-datepicker .date-picker-input {
    height: 100px !important;
  }

  .react-google-flight-datepicker .date {
    width: 100% !important;
  }

  .react-google-flight-datepicker .date:nth-child(2) {
    margin-left: 0 !important;
    border-top: none;
  }

  .pricing-container {
    min-width: 0;
    width: 100%;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .book-total {
    margin-top: 30px;
  }

  .book-hours-container, .book-price-container {
    width: 100%;
    border: 1px solid lightgray;
    margin: 0;
  }

  .book-hours-container {
    border-bottom: none;
  }

  .input-line {
    display: none;
  }

  .button-container {
    right: 10%;
    bottom: 25px;
    width: 80%;
  }

  .profile-item {
    flex-direction: column;
    padding: 50px 10%;
    align-items: center;
    justify-content: center;
  }

  .profile-item > div > * {
    text-align: center;
  }

  .my-profile-img {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  .self-view > h2 {
    text-align: center;
  }

  .experience-section {
    flex-direction: column;
  }

  .experience {
    width: 100%;
  }

  .experience-section > *:last-child {
    margin-top: 50px;
  }

  .profile-item > h1 {
    text-align: center;
  }

  .works-col-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .works-container {
    flex-direction: column;
  }

  .works-item {
    width: 100%;
  }

  .works-header {
    font-size: 28px;
    line-height: 28px;
  }

  .works-header > *:first-child {
    /* margin-bottom: 10px; */
  }

  .work-iframe {
    width: 100%;
    height: 300px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {

  .book-textarea {
    font-size: 14px;
    line-height: 20px;
  }

  .works-item {
    min-width: 150px;
    padding: 30px;
  }

  .works-header {
    font-size: 28px;
    line-height: 30px;
  }

  .works-section-header {
    font-size: 18px;
    line-height: 22px;
  }

  .work-iframe {
    height: 250px;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}
