.cookies {
  background-color: #474448;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  width: 80%;
  padding: 20px 10%;
  color: white;
  z-index: 100;
}

.cookies > * {
  color: white;
}

.cookies > * > a,
.cookies > * > * > a {
  color: white;
  font-weight: bold;
}

.cookies > * > a:hover,
.cookies > * > * > a:hover {
  text-decoration: underline;
}

.homepage {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.covid {
  height: auto;
  width: 100%;
  background-color: #474448;
  color: white;
  /* display: flex;
  align-items: center; */
  font-size: 14px;
  padding: 10px 10%;
  text-align: center;
}

.padding {
  padding: 0 10%;
}

.url {
  color: white;
  display: inline;
  font-weight: bold;
}

.url:hover {
  /* color: #05D9B2; */
  text-decoration: underline;
}

.homepage-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: -50px;
}

.homepage-image {
  width: 40vw;
}

.one-liner {
  background-color: #f5f5f5;
  padding: 75px 20%;
}

.bip {
  width: 40px;
  margin-left: 10px;
}

.process-container {
  padding: 0 20%;
  margin: 100px 0;
}

.process {
  position: relative;
  margin-top: 50px;
}

.process-item {
  margin: 50px 0;
  box-shadow: 0px 20px 50px rgba(71, 68, 72, 0.1);
  display: flex;
  position: relative;
  background-color: white;
  width: 100%;
}

.process-label {
  border-radius: 50px;
  position: absolute;
  left: 80px;
  top: -20px;
  height: 40px;
  width: 40px;
  background-color: #8f8dff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
}

.process-line {
  height: 80%;
  width: 3px;
  background-color: #8f8dff;
  position: absolute;
  left: 99px;
  top: 100px;
  z-index: 0;
}

.process-img-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 200px;
}

.process {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.process-img {
  /* min-height: 200px; */
  /* min-width: 200px; */
  /* max-width: 300px; */
  /* max-height: 300px; */
  width: 100%;
  height: auto;
  object-fit: cover;
}

.process-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px;
  width: 75%
}

.process-text > * {
  margin: 5px 0;
  color: #474448;
}

.homepage-carousel {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.homepage-carousel-container {
  overflow: hidden;
}

.horizontalSlider___281Ls {
  overflow: visible !important;
}

.homepage-slide-container {
  position: relative;
}

.homepage-slider-container {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.homepage-slide {
  padding: 50px 60px 75px 60px;
  text-align: center;
  height: 100%;
  width: 100%;
}

.homepage-slide > * {
  color: #474448;
  text-align: center;
}

.dot-group {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
}

.carousel__dot {
  border: 1px solid #474448;
  background-color: #f5f5f5;
  height: 10px !important;
  width: 10px !important;
  color: #474448;
  padding: 0;
  margin: 5px;
  border-radius: 10px;
}

.carousel__dot:hover {
  background-color: #474448;
  transition: all 0.2s linear;
}

.carousel__dot:disabled {
  border: none;
  background-color: #474448;
}

.interstitial {
  justify-content: space-between;
  padding: 75px 10% 50px 10%;
}

.interstitial-item {
  width: 25%;
  min-width: 200px;
}

.interstitial-item > * {
  color: #474448;
}

.interstitial-item > h2 {
  margin-bottom: 16px;
  color: #8f8dff;
}

.interstitial-icon {
  font-size: 50px;
}

.example-company-square,
.example-company-rect {
  filter: grayscale(0.5);
}

.example-company-square:hover,
.example-company-rect:hover {
  filter: grayscale(0);
  transition: all 0.2 ease-in;
  -webkit-transition: -webkit-filter 200ms linear;
}

.example-company-square-container {
  margin: 0 50px;
  min-width: 100px;
  width: 20%;
  height: auto;
}

.example-company-square {
  width: 100%;
}

.example-company-rect-container {
  margin: 0 50px;
  min-width: 150px;
  width: 30%;
  height: auto;
}

.example-company-rect {
  width: 100%;
}

.accordion {
  width: 75%;
  margin: 50px 0;
}

.Collapsible {
  font-size: 24px;
  font-weight: 400;

  color: #474448;
  padding: 20px 0px;
  border-bottom: solid 2px #f5f5f5;
  background-color: transparent;
  position: relative;
}

.Collapsible__trigger {
  margin-left: 40px;
}

.Collapsible__trigger:hover {
  cursor: pointer;
}

/* The link which when clicked opens the collapsable area */
.Collapsible__trigger::after {
  background-image: url("chevron.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 12px;
  content: " ";
  position: absolute;
  left: 10px;
  top: 32px;
  display: block;
  transition: transform 200ms;
}

.is-open.Collapsible__trigger::after {
  transform: rotateZ(180deg);
}

.Collapsible__contentInner {
  margin: 0 40px;
}

.Collapsible__contentInner > p {
  margin-bottom: 0;
  font-weight: 300;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  background-color: #474448;
  color: white;
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);

  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.modal-screen {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.modal-screen:hover {
  cursor: pointer;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  .process-img {
    height: 250px;
    width: 250px;
  }

  .process-label {
    left: 105px;
  }

  .process-line {
    left: 124px;
    top: 100px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .covid {
    /* flex-direction: column;
    align-items: flex-start; */
    height: auto;
    padding: 15px 10%;
  }

  .homepage-1 {
    flex-direction: column-reverse;
  }

  .homepage-image {
    width: 75%;
  }

  .homepage-text {
    margin-top: 25px;
  }

  .modal {
    height: 100vh;
    width: 100%;
  }

  .modal-x {
    font-size: 28px;
    top: 20px;
    right: 20px;
  }

  .one-liner {
    padding: 50px 10%;
  }

  .process-container {
    padding: 0 10%;
    margin: 50px 0;
  }

  .process-img {
    width: 100%;
    height: auto;
  }

  .process-item {
    flex-direction: column;
  }

  .process-text {
    padding: 25px 25px;
  }

  .process-label {
    left: 50%;
    transform: translateX(-50%);
  }

  .process-line {
    left: 50%;
    transform: translateX(-50%);
  }

  .interstitial {
    flex-direction: column;
    align-items: center;
  }

  .interstitial-item {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .interstitial > :last-child {
    margin-bottom: 0;
  }

  .accordion {
    width: 100%;
  }

  .Collapsible__trigger {
    font-size: 18px;
  }

  .Collapsible__trigger::after {
    /* width: 15px; */
    height: 10px;
  }

  .example-company-square-container {
    min-width: 75px;
    height: auto;
    margin: 0;
  }

  .example-company-rect-container {
    min-width: 75px;
    height: auto;
    margin: 0;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}
