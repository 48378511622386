@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&family=Source+Sans+Pro:wght@300;400;700&display=swap);
/* hex codes: */
/* purple: #8F8DFF */
/* (lighter) green: #2AF3CE */
/* (darker) green: #05D9B2 */
/* gray: #474448 */

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
}

.flex-row,
.flex-row-comp {
  display: flex;
  flex-direction: row;
}

.flex-row-comp {
  display: flex;
  flex-direction: row
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  display: inline-flex;
}

.logo {
  color: #474448;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
}

.logo-img {
  height: 36px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in;
}

.logo-container:hover .logo-img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in;
}

.menu {
  padding: 20px 10%;
}

.menu-item {
  font-weight: bold;
  color: #474448;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.menu-item:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

.menu-item:hover:after {
  transform: scaleX(1);
}

.react-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  font-weight: bold;
  color: #474448;
  position: relative;
  display: inline-block;
}

.link:hover {
  color: #8f8dff;
  transition: all 0.2s ease-in;
}

.link:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

.active-item:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(1);
}

.container-stint {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-x {
  top: 10px;
  right: 10px;
  position: absolute;
  font-size: 20px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in;
  z-index: 10;
  color: white;
}

.modal-x:hover {
  color: #8f8dff;
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.2s ease-in;
}

.footer {
  padding: 30px 50px;
  font-size: 12px;
  background-color: #474448;
  color: white;
}

.contact-us {
  color: white;
}

.contact-us:hover {
  text-decoration: underline;
}

.button {
  background: linear-gradient(to left, #8f8dff 50.1%, #474448 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
  position: relative;
  transition: background-position 0.2s ease-in;
}

.button:hover {
  cursor: pointer;
  box-shadow: 0px 8px 8px rgba(71, 68, 72, 0.15);
  transition: all 0.2s ease-in;
  background-position: left bottom;
}

.button:focus {
  outline: 0;
}

.menu-propic-solo {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.menu-profile {
  margin-left: 50px;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.menu-profile-dropdown {
  display: none;
  position: absolute;
  min-width: 150px;
  box-shadow: 0px 8px 12px rgba(71, 68, 72, 0.1);
  z-index: 1;
  right: 0;
}

/* Links inside the dropdown */
.menu-profile-dropdown > * {
  background: white;
  color: #474448;
  font-size: 14px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

/* Change color of dropdown links on hover */
.menu-profile-dropdown > *:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Show the dropdown menu on hover */
.menu-profile:hover .menu-profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.sign-out {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  border-top: 1px solid #f5f5f5;
  width: 100%;
  padding: 15px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

h1 {
  font-weight: bold;
  font-size: 54px;
  margin: 10px 0;

  color: #474448;
}

.h1-smaller {
  font-weight: bold;
  font-size: 36px;
  margin: 10px 0;
  text-align: center;

  color: #474448;
}

h2 {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 26px;

  color: #8f8dff;
}

h3 {
  font-size: 24px;
  font-weight: 400;

  color: #474448;
}

h4 {
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
}

.interstitial-text > p {
  color: #ffffff;
}

p {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  color: #474448;
}

.subtitle {
  font-size: 12px;
  color: #474448;
}

.nobreak {
  white-space: nowrap;
}

.nobreak-comp {
  white-space: nowrap;
}

.input {
  font-family: "IBM Plex Mono", monospace;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  height: 36px;
  font-size: 14px;
  width: 300px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.input:focus {
  /* border-bottom: 4px solid #8F8DFF; */
  background-color: #f2f2ff;
  transition: all 0.2s ease-in;
}

.dropdown {
  font-family: "IBM Plex Mono", monospace;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 0;
  height: 32px;
  font-size: 14px;
  background-color: none;
}

.about-text {
  /* height: 90vh; */
  align-items: space-between;
  justify-content: center;
  padding: 100px 20%;
}

.about-card-container {
  justify-content: space-between;
}

.about-card {
  width: 45%;
}

/* Autocomplete */

.autocomplete-container {
  position: relative;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8dff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8dff;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8dff;
  opacity: 1; /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8dff;
  opacity: 1; /* Firefox */
}

.search-box-container {
  min-width: 25px;
  max-width: 90%;
  border: none;
  border-bottom: 4px solid #8f8dff;
  border-radius: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;

  color: #8f8dff;
  background-color: transparent;

  margin: 0 4px;
  display: inline-block;

  transition: border-bottom 0.2s ease-in;
  z-index: 3;
}

.search-box-container:focus {
  /* border-bottom: 4px solid #474448; */
  background-color: #f2f2ff;
  outline: none;
  transition: border-bottom 0.2s ease-in;
}

:disabled {
  background: #b0b0b0;
  cursor: not-allowed;
}

:disabled:hover {
  background: #b0b0b0;
  cursor: not-allowed;
  box-shadow: none;
}

.hide {
  position: absolute;
  visibility: hidden;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;

  color: #8f8dff;
}

.ac-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  list-style: none;
  transition: width 0.3s;
  position: absolute;
  left: 4px;
  z-index: 10;
}

.ac-dropdown-item {
  background-color: #474448;
  color: white;
  font-weight: bold;
  padding: 10px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  width: 100%;
  z-index: 10;
}

.ac-dropdown-item:hover {
  background-color: #8f8dff;
  cursor: pointer;
  transition: 0.3s all;
}

.option-active {
  color: #8f8dff;
}

.option-active:hover {
  color: #474448;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Media Queries */

.menu-desktop {
  display: flex;
}

.menu > *:last-child {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  right: 36px;
  top: 36px;
}

.bm-burger-button > button:focus {
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #474448;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross-button > button:focus {
  outline: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* General sidebar styles */
.bm-menu {
  background: #474448;
  /* padding: 50px; */
  font-size: 18px;
}

/* Wrapper for item list */
.bm-item-list > *:first-child {
  display: flex !important;
  margin-top: 75px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  margin: 20px 0;
  font-weight: bold;
  padding: 0px 50px;
}

.bm-item:hover {
  color: #8f8dff;
}

.bm-item:focus {
  outline: none;
}

.menu-name {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px;
  margin-top: 10px;
}

.menu-propic {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.menu-burger-profile {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 20px;
  border: 1px solid white;
  font-size: 12px;
  margin-top: 10px;
  min-width: 120px;
  display: flex;
  justify-content: center;
}

.menu-burger-profile:hover {
  background-color: #f5f5f5;
  color: #474448;
}

.menu-item-burger {
  color: #8f8dff;
}

.sign-out-burger {
  width: 100%;
  border-top: 1px solid #f5f5f5;
  padding: 20px;
  position: absolute;
  bottom: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.sign-out-burger:hover {
  background-color: #f5f5f5;
  color: #474448;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 44px;
  }

  .h1-smaller {
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
  }

  .search-box-container {
    font-size: 20px;
  }

  .hide {
    font-size: 20px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .flex-row-comp {
    flex-direction: column;
  }

  .logo {
    font-size: 20px;
    display: none;
  }

  .logo-img {
    height: 28px;
  }

  .menu-item {
    font-size: 10px;
  }

  .flex-row-comp {
    display: flex;
    flex-direction: column
  }

  h1 {
    font-size: 32px;
  }

  .h1-smaller {
    font-size: 26px;
  }

  .nobreak-comp {
    white-space: normal;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }

  .button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .search-box-container {
    font-size: 18px;
  }

  .hide {
    font-size: 18px;
  }

  .footer {
    flex-direction: column;
    padding: 30px 10%;
  }

  .footer > a {
    margin-top: 10px;
  }

  .menu-desktop {
    display: none;
  }

  .menu > *:last-child {
    display: block;
  }

  .bm-burger-button {
    right: 30px;
    top: 22px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
  .logo {
    font-size: 16px;
  }

  .logo-img {
    height: 20px;
  }

  .menu-propic {
    width: 75px;
    height: 75px;
  }

  .bm-item-list > *:first-child {
    margin-bottom: 50px !important;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.profile-container {
  margin: 100px 0;
}

.profile-container > * {
  margin-bottom: 50px;
}

.profile-container > :last-child {
  margin-bottom: 0px;
}

.stint-dialogue {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 200% 100%;
  -webkit-animation: gradient 2s linear infinite;
          animation: gradient 2s linear infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;

  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 50px;
  -webkit-filter: opacity(1);
          filter: opacity(1);
  transition: opacity 0.2s ease-in;
}

.stint-dialogue > * {
  color: white;
  margin: 0;
  margin-bottom: 20px;
  -webkit-filter: opacity(1);
          filter: opacity(1);
  transition: opacity 0.2s ease-in;
}

.stint-dialogue > :last-child {
  margin-bottom: 0;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.loading {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 200% 100%;
  -webkit-animation: gradient 2s linear infinite;
          animation: gradient 2s linear infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;

  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 50px;

  -webkit-filter: opacity(0.25);

          filter: opacity(0.25);
  transition: opacity 0.2s ease-in;
}

.loading > * {
  margin: 0;
  margin-bottom: 20px;
  -webkit-filter: opacity(0);
          filter: opacity(0);
  transition: opacity 0.2s ease-in;
}

.loading > :last-child {
  margin-bottom: 0;
}

.hline {
  border: 2px solid #f5f5f5;
  width: 100%;
}

/* Student form */

.student-dialogue {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  padding: 50px;
}

.student-dialogue > * {
  margin-bottom: 40px;
}

.student-dialogue > :last-child {
  margin-bottom: 0;
}

.student-dialogue-block {
}

.student-dialogue-block > * {
  margin: 0;
  margin-bottom: 20px;
}

.student-dialogue-block > :last-child {
  margin-bottom: 0;
}

.custom-select {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 4px solid #8f8dff;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;

  color: #8f8dff;

  margin: 0 4px;
  display: inline-block;
}

.custom-select:hover {
  cursor: pointer;
}

.optional-chunk {
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
  z-index: 5;
  position: relative;
}

.optional-chunk * {
  z-index: 10;
}

.optional-chunk > h3 {
  margin: 0;
}

/* Second student form */

.skills-header:hover {
  cursor: pointer;
}

.skills-header:hover .skills-header-row > h3 {
  color: #8f8dff;
}

.skills-header-row {
  display: flex;
  align-items: center;
}

.skills-header-row > * {
  margin: 0;
  margin-right: 20px;
}

.student-skill-status {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;

  color: #ffffff;
}

.skill-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.skill-container > *:last-child {
  margin-right: 0;
}

.skill {
  padding: 5px 40px;
  margin: 5px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill:hover {
  cursor: pointer;
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background-color: #e0e0e0;
  transition: background-color 0.4s linear;
}

.skill-name {
  display: inline-flex;
  z-index: 5;
}

.skill-level {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.skill:hover > .skill-name {
  visibility: hidden;
}

.skill-level,
.plus,
.minus {
  visibility: hidden;
}

.skill:hover > .skill-level,
.skill:hover > .plus,
.skill:hover > .minus {
  visibility: visible;
  z-index: 5;
}

.plus {
  position: absolute;
  right: 0px;
  padding: 5px 10px;
  z-index: 6;
  background-color: none;
}

.minus {
  position: absolute;
  left: 0px;
  padding: 5px 10px;
  z-index: 6;
  background-color: none;
}

.plus:hover,
.minus:hover {
  background-color: rgb(0, 0, 0, 0.1);
  transition: all 0.1s linear;
}

.skill-bar {
  background-color: #05d9b2;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 0;
  width: 0%;
  transition: width 0.1s ease-in;
}

#hidden-skill-level {
  visibility: hidden;
  position: absolute;
}

.upload {
  justify-content: center;
  align-self: flex-start;
  margin-top: 30px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
  align-self: flex-start;
}

[type="file"] + label {
  background: linear-gradient(to left, #8f8dff 50.1%, #474448 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  align-self: flex-start;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
  box-shadow: 0px 8px 8px rgba(71, 68, 72, 0.15);
  transition: all 0.2s ease-in;
  background-position: left bottom;
}

[type="file"]:focus + label {
  outline: 4px solid #8f8dff;
  outline: -webkit-focus-ring-color auto 5px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  .custom-select {
    font-size: 20px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .custom-select {
    font-size: 18px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

.cookies {
  background-color: #474448;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  width: 80%;
  padding: 20px 10%;
  color: white;
  z-index: 100;
}

.cookies > * {
  color: white;
}

.cookies > * > a,
.cookies > * > * > a {
  color: white;
  font-weight: bold;
}

.cookies > * > a:hover,
.cookies > * > * > a:hover {
  text-decoration: underline;
}

.homepage {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.covid {
  height: auto;
  width: 100%;
  background-color: #474448;
  color: white;
  /* display: flex;
  align-items: center; */
  font-size: 14px;
  padding: 10px 10%;
  text-align: center;
}

.padding {
  padding: 0 10%;
}

.url {
  color: white;
  display: inline;
  font-weight: bold;
}

.url:hover {
  /* color: #05D9B2; */
  text-decoration: underline;
}

.homepage-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: -50px;
}

.homepage-image {
  width: 40vw;
}

.one-liner {
  background-color: #f5f5f5;
  padding: 75px 20%;
}

.bip {
  width: 40px;
  margin-left: 10px;
}

.process-container {
  padding: 0 20%;
  margin: 100px 0;
}

.process {
  position: relative;
  margin-top: 50px;
}

.process-item {
  margin: 50px 0;
  box-shadow: 0px 20px 50px rgba(71, 68, 72, 0.1);
  display: flex;
  position: relative;
  background-color: white;
  width: 100%;
}

.process-label {
  border-radius: 50px;
  position: absolute;
  left: 80px;
  top: -20px;
  height: 40px;
  width: 40px;
  background-color: #8f8dff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
}

.process-line {
  height: 80%;
  width: 3px;
  background-color: #8f8dff;
  position: absolute;
  left: 99px;
  top: 100px;
  z-index: 0;
}

.process-img-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 200px;
}

.process {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.process-img {
  /* min-height: 200px; */
  /* min-width: 200px; */
  /* max-width: 300px; */
  /* max-height: 300px; */
  width: 100%;
  height: auto;
  object-fit: cover;
}

.process-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 50px;
  width: 75%
}

.process-text > * {
  margin: 5px 0;
  color: #474448;
}

.homepage-carousel {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.homepage-carousel-container {
  overflow: hidden;
}

.horizontalSlider___281Ls {
  overflow: visible !important;
}

.homepage-slide-container {
  position: relative;
}

.homepage-slider-container {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.homepage-slide {
  padding: 50px 60px 75px 60px;
  text-align: center;
  height: 100%;
  width: 100%;
}

.homepage-slide > * {
  color: #474448;
  text-align: center;
}

.dot-group {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
}

.carousel__dot {
  border: 1px solid #474448;
  background-color: #f5f5f5;
  height: 10px !important;
  width: 10px !important;
  color: #474448;
  padding: 0;
  margin: 5px;
  border-radius: 10px;
}

.carousel__dot:hover {
  background-color: #474448;
  transition: all 0.2s linear;
}

.carousel__dot:disabled {
  border: none;
  background-color: #474448;
}

.interstitial {
  justify-content: space-between;
  padding: 75px 10% 50px 10%;
}

.interstitial-item {
  width: 25%;
  min-width: 200px;
}

.interstitial-item > * {
  color: #474448;
}

.interstitial-item > h2 {
  margin-bottom: 16px;
  color: #8f8dff;
}

.interstitial-icon {
  font-size: 50px;
}

.example-company-square,
.example-company-rect {
  -webkit-filter: grayscale(0.5);
          filter: grayscale(0.5);
}

.example-company-square:hover,
.example-company-rect:hover {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  transition: all 0.2 ease-in;
  -webkit-transition: -webkit-filter 200ms linear;
}

.example-company-square-container {
  margin: 0 50px;
  min-width: 100px;
  width: 20%;
  height: auto;
}

.example-company-square {
  width: 100%;
}

.example-company-rect-container {
  margin: 0 50px;
  min-width: 150px;
  width: 30%;
  height: auto;
}

.example-company-rect {
  width: 100%;
}

.accordion {
  width: 75%;
  margin: 50px 0;
}

.Collapsible {
  font-size: 24px;
  font-weight: 400;

  color: #474448;
  padding: 20px 0px;
  border-bottom: solid 2px #f5f5f5;
  background-color: transparent;
  position: relative;
}

.Collapsible__trigger {
  margin-left: 40px;
}

.Collapsible__trigger:hover {
  cursor: pointer;
}

/* The link which when clicked opens the collapsable area */
.Collapsible__trigger::after {
  background-image: url(/static/media/chevron.f382181f.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 12px;
  content: " ";
  position: absolute;
  left: 10px;
  top: 32px;
  display: block;
  transition: transform 200ms;
}

.is-open.Collapsible__trigger::after {
  transform: rotateZ(180deg);
}

.Collapsible__contentInner {
  margin: 0 40px;
}

.Collapsible__contentInner > p {
  margin-bottom: 0;
  font-weight: 300;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;

  background-color: #474448;
  color: white;
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);

  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}

.modal-screen {
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.modal-screen:hover {
  cursor: pointer;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  .process-img {
    height: 250px;
    width: 250px;
  }

  .process-label {
    left: 105px;
  }

  .process-line {
    left: 124px;
    top: 100px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .covid {
    /* flex-direction: column;
    align-items: flex-start; */
    height: auto;
    padding: 15px 10%;
  }

  .homepage-1 {
    flex-direction: column-reverse;
  }

  .homepage-image {
    width: 75%;
  }

  .homepage-text {
    margin-top: 25px;
  }

  .modal {
    height: 100vh;
    width: 100%;
  }

  .modal-x {
    font-size: 28px;
    top: 20px;
    right: 20px;
  }

  .one-liner {
    padding: 50px 10%;
  }

  .process-container {
    padding: 0 10%;
    margin: 50px 0;
  }

  .process-img {
    width: 100%;
    height: auto;
  }

  .process-item {
    flex-direction: column;
  }

  .process-text {
    padding: 25px 25px;
  }

  .process-label {
    left: 50%;
    transform: translateX(-50%);
  }

  .process-line {
    left: 50%;
    transform: translateX(-50%);
  }

  .interstitial {
    flex-direction: column;
    align-items: center;
  }

  .interstitial-item {
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  .interstitial > :last-child {
    margin-bottom: 0;
  }

  .accordion {
    width: 100%;
  }

  .Collapsible__trigger {
    font-size: 18px;
  }

  .Collapsible__trigger::after {
    /* width: 15px; */
    height: 10px;
  }

  .example-company-square-container {
    min-width: 75px;
    height: auto;
    margin: 0;
  }

  .example-company-rect-container {
    min-width: 75px;
    height: auto;
    margin: 0;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

.thank-you-background {
  height: 100vh;
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 400% 100%;
  -webkit-animation: gradient 2s linear infinite;
          animation: gradient 2s linear infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}

.thank-you {
  width: 40%;
  text-align: center;
}

.thank-you > * {
  color: #474448;
}

.private-policy {
  margin-top: 100px;
  margin-bottom: 200px;
}

.fof-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: -50px;
}

.fof {
  width: 40%;
  text-align: center;
}

.fof-img {
  width: 150px;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
}

.company-text {
  margin-top: 100px;
  margin-bottom: 150px;
  margin-right: 100px;
}

.payment-container > img {
  margin-top: 75px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .fof,
  .thank-you {
    width: 65%;
    text-align: center;
  }

  .fof-img {
    width: 100px;
  }

  .payment-container {
    flex-direction: column-reverse;
  }

  .payment-container > img {
    margin-top: 20px;
    width: 90%;
  }

  .company-text {
    width: 100%;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

.book-container {
  width: 100%;
  padding: 50px 10% 100px 10%;
  display: flex;
  justify-content: space-between;
}

.book-title {
  padding: 20px 50px;
  width: 80%;
  box-shadow: 0px 0px 20px rgba(71, 68, 72, 0.15);
  /* border: 2px solid #8F8DFF; */
  background-color: white;
  bottom: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  z-index: 15;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 75px;
}

.book-avatar-container {
  width: 100%;
  height: 120px;
  border-radius: 8px;
  overflow-y: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}

.book-avatar-container > h3, .book-avatar-container > i {
  z-index: 4;
  color: white;
}

.book-avatar {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}

.book-count {
  border-radius: 50px;
  height: 28px;
  width: 28px;
  color: #474448;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 11px;
  margin-right: 10px;
}

.book-count-active {
  background-color: #8f8dff;
  color: white;
  border: 1px solid #8f8dff
}

.booking-week-info > *:first-child {
  margin: 0;
  margin-right: 20px
}

.book-title-opened {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-title-opened > img {
  height: 5vh;
  border-radius: 50px;
  margin-right: 20px;
}

.date-picker-input {
  height: 44px !important;
}

/* .date-picker-date-group > *:first-child {
  width: 55% !important;
}

.date-picker-date-group > *:last-child {
  width: 45% !important;
} */

.react-google-flight-datepicker {
  width: 40%;
  max-width: 800px;
}

.book-calendar {
  padding: 0 !important;
  border-radius: 0 !important;

}

.dialog-date-picker {
  border-radius: 0 !important
}

.react-google-flight-datepicker .date-picker-input, .react-google-flight-datepicker .date, .react-google-flight-datepicker .date.is-focus::after {
  border-radius: 0 !important
}

.react-google-flight-datepicker .day.selected.hovered::after {
  background-color: #8F8DFF !important
}

.react-google-flight-datepicker .day.hovered {
  background-color: #e4e4f7 !important
}

.react-google-flight-datepicker .day.selected.hovered.end, .react-google-flight-datepicker .day.hovered.end {
  background: linear-gradient(90deg, #e4e4f7 50%, #fff 50%) !important
}

.react-google-flight-datepicker .day.selected.hovered {
  background: linear-gradient(90deg, #fff 50%, #e4e4f7 50%) !important
}

.react-google-flight-datepicker .day:hover::after {
  border: 2px solid #8F8DFF !important;
}

.react-google-flight-datepicker .dialog-footer .submit-button {
  background: #8F8DFF !important;
  border-radius: 0 !important;
}

.date-placeholder {
  font-size: 0.9rem !important;
  line-height: normal !important;
  letter-spacing: 0.2px !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

.book-hours-container, .book-price-container {
  height: 66px;
  width: 50%;
  outline: none;
  border: 1px solid lightgray;
  border-left: none;
  background-color: white;
  padding: 0 13px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.book-container-content {
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  width: 100%;
}

.book-hours-container {
  width: 50%;
  border-left: 1px solid lightgray;
  border-right: none;
}

.book-container-content > svg {
  margin-right: 10px;
  color: #8F8DFF;
  font-size: 20px;
}

.book-price, .book-hours {

  /* font-weight: bold; */
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 25%;
  flex-grow: 1;

  color: #3c4043;

  outline: none;
  border: none;
}

.book-total {
  text-align: right;
  position: relative;
  margin-top: 100px;
}

.book-total > * {
  margin: 0;
}

.button-container {
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: flex-end
}

.input-line {
  height: 45px;
  width: 1px;
  background-color: lightgray;
}

.half-container > h1 {
  font-size: 44px;
  margin: 0;
}

.pricing-container {
  width: 30%;
  min-width: 400px;
  background-color: #f5f5f5;
  box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.15);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0;
  align-self: flex-start;
  position: sticky;
  position: -webkit-sticky;
  top: 50px;
  /* position: fixed;
  right: 10%; */
}

.half-container-book {
  width: 40%;
  position: relative;
}

.half-container-book-content {
  margin-top: 50px;
}

.booking-category-select-container, .booking-category-textarea-container {
  height: 66px;
  outline: none;
  border: 1px solid lightgray;
  border-bottom: none;
  background-color: white;
  padding: 0 30px;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #3c4043;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.booking-category-textarea-container {
  height: auto;
  border-bottom: 1px solid lightgray;
  padding: 20px 30px;
}

.booking-category-select, .booking-category-input {
  font-family: "Source Sans Pro", sans-serif;
  outline: none;
  border: none;
  background-color: white;
  color: #474448;
  width: 100%;
  margin-top: 2px;
  margin-left: -4px;
}

.booking-category-input {
  margin-left: 0
}

.booking-category-input::-webkit-input-placeholder {
  color: gray;
}

.booking-category-input:-ms-input-placeholder {
  color: gray;
}

.booking-category-input::-ms-input-placeholder {
  color: gray;
}

.booking-category-input::placeholder {
  color: gray;
}

.book-textarea {
  border: none;
  outline: none;
  height: 150px;
  resize: none;
  color: #474448;
  font-family: "Source Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  margin-left: -3px;
  margin-top: 2px;
}

.book-price::-webkit-input-placeholder, .book-textarea::-webkit-input-placeholder, .book-hours::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.56);
}

.book-price:-ms-input-placeholder, .book-textarea:-ms-input-placeholder, .book-hours:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.56);
}

.book-price::-ms-input-placeholder, .book-textarea::-ms-input-placeholder, .book-hours::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.56);
}

.book-price::placeholder, .book-textarea::placeholder, .book-hours::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0,0,0,0.56);
}

.profile-item:first-of-type {
  padding-bottom: 0;
}

.profile-item:last-of-type {
  padding-bottom: 150px;
}

.profile-item {
  padding: 75px 10%;
}

.my-profile-img {
  height: 125px;
  width: 125px;
  border-radius: 75px;
  margin-right: 50px;
}

.edit-profile-img {
  position: relative;
  margin-right: 50px;
}

.edit-profile-img > img {
  cursor: pointer;
  margin-right: 0;
}

.edit-profile-img:hover img {
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
}

.edit-profile-txt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  visibility: hidden;
  z-index: 10;
  pointer-events: none;
}

.edit-profile-img:hover .edit-profile-txt {
  visibility: visible;
}

.self-view {
  padding: 75px 10%;
  background-color: #474448;
}

.experience-section {
  justify-content: space-between;
}

.experience {
  width: 45%;
}

.experience-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.experience-item {
  margin-bottom: 50px;
  box-shadow: 0px 10px 20px rgba(71, 68, 72, 0.15);
  position: relative;
  background-color: white;
  padding: 25px 30px;
  align-self: flex-start;
  color: #474448;
}

.experience-container > *:last-child {
  margin-bottom: 0px;
}

.experience-label {
  border-radius: 50px;
  position: absolute;
  left: 15px;
  top: -15px;
  height: 30px;
  width: 30px;
  background-color: #8f8dff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 10px;
}

.experience-line {
  height: 80%;
  width: 3px;
  background-color: #8f8dff;
  position: absolute;
  left: 28px;
  top: 0px;
  z-index: 0;
}

.section-header {
  color: #474448;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
}

.profile-skills,
.profile-works,
.profile-awards {
  margin-top: 50px;
}

.skill-static {
  padding: 5px 40px;
  margin: 5px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-name-static {
  display: inline-flex;
  z-index: 5;
}

.works-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.works-item {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.1);
  background-color: white;
  padding: 50px;
  width: 45%;
  min-width: 300px;
  margin: 40px 0;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.works-item:hover {
  box-shadow: 0px 12px 32px rgba(71, 68, 72, 0.15);
  transform: scale(1.01) translateY(-1%);
  transition: all 0.2s ease-in;
}

.column-works-item {
  width: 100%;
}

.works-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
}

.works-section {
  margin-top: 25px;
  position: relative;
  /* display: flex;
  flex-direction: column; */
}

.works-section-item {
  /* align-self: flex-start; */
}

.works-section-item-link:hover {
  color: #8f8dff;
}

.works-col-wrapper {
  max-width: 45%;
  width: 45%;
}

.works-header-img {
  height: 36px;
  margin-right: 15px;
}

.github {
  color: #24292e;
}

.medium {
  color: #018f69;
}

.instagram {
  color: #dd2a7b;
}

.gray {
  color: #474448;
}

.works-section-header {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 20px;
  color: #474448;
}

.works-section-item {
  margin-top: 15px;
  color: #474448;
}

.works-laptop {
  width: 100%;
  z-index: 2;
  position: relative;
  pointer-events: none;
}

.works-laptop-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  z-index: 1;
}

.personal-website {
  width: 100%;
  position: relative;
  cursor: pointer;
  pointer-events: all;
}

.medium-img {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-position: center;
  margin: 10px 0;
}

.insta-propic {
  height: 30px;
  border-radius: 15px;
}

.insta-logo {
  width: 50%;
}

.insta-handle {
  font-size: 12px;
  margin-left: 15px;
  color: #474448;
}

.insta-grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.insta-grid div::before {
  content: "";
  padding-bottom: 100%;
  display: inline-block;
  vertical-align: top;
}

.insta-img {
  width: 100%;
  background-size: cover;
  background-position: center;
  /* height: 100%; */
  /* background-color: #f5f5f5; */
  /* margin-bottom: 100%; */
}

.work-iframe {
  width: 75%;
  border: 4px solid #474448;
  height: 350px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {

  .book-container {
    flex-direction: column-reverse;
    padding-top: 0;
  }

  .pricing-container {
    position: relative;
  }

  .booking-category-select {
    font-size: 14px;
    margin-left: 0;
  }

  .booking-week-info > *:first-child {
    margin: 0;
    margin-bottom: 10px;
  }

  .half-container-book {
    width: 100%;
    position: static;
  }

  .book-container-inner {
    overflow-y: scroll;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .half-container-book {
    margin-bottom: 50px;
  }

  .react-google-flight-datepicker .icon-calendar.mobile {
    display: none !important;
  }

  .react-google-flight-datepicker .date-picker-date-group {
    flex-direction: column;
  }

  .react-google-flight-datepicker .date-picker-input {
    height: 100px !important;
  }

  .react-google-flight-datepicker .date {
    width: 100% !important;
  }

  .react-google-flight-datepicker .date:nth-child(2) {
    margin-left: 0 !important;
    border-top: none;
  }

  .pricing-container {
    min-width: 0;
    width: 100%;
    margin-bottom: 100px;
    margin-right: 0;
  }

  .book-total {
    margin-top: 30px;
  }

  .book-hours-container, .book-price-container {
    width: 100%;
    border: 1px solid lightgray;
    margin: 0;
  }

  .book-hours-container {
    border-bottom: none;
  }

  .input-line {
    display: none;
  }

  .button-container {
    right: 10%;
    bottom: 25px;
    width: 80%;
  }

  .profile-item {
    flex-direction: column;
    padding: 50px 10%;
    align-items: center;
    justify-content: center;
  }

  .profile-item > div > * {
    text-align: center;
  }

  .my-profile-img {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }

  .self-view > h2 {
    text-align: center;
  }

  .experience-section {
    flex-direction: column;
  }

  .experience {
    width: 100%;
  }

  .experience-section > *:last-child {
    margin-top: 50px;
  }

  .profile-item > h1 {
    text-align: center;
  }

  .works-col-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .works-container {
    flex-direction: column;
  }

  .works-item {
    width: 100%;
  }

  .works-header {
    font-size: 28px;
    line-height: 28px;
  }

  .works-header > *:first-child {
    /* margin-bottom: 10px; */
  }

  .work-iframe {
    width: 100%;
    height: 300px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {

  .book-textarea {
    font-size: 14px;
    line-height: 20px;
  }

  .works-item {
    min-width: 150px;
    padding: 30px;
  }

  .works-header {
    font-size: 28px;
    line-height: 30px;
  }

  .works-section-header {
    font-size: 18px;
    line-height: 22px;
  }

  .work-iframe {
    height: 250px;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

.company-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 50px;
}

/* .company-2 {
  padding-top: 100px;
  padding-bottom: 100px;
} */

.tri {
  justify-content: space-between;
  margin: 50px 0;
}

.tri-image {
}

.tri-text {
  width: 50%;
}

.stipulation {
  font-size: 14px;
  font-weight: 300;
}

.tri-title {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;

  color: #8f8dff;
}

.company-carousel {
  width: 100%;
  position: relative;
}

.carousel-container {
  padding: 32px 32px;
  margin-top: -20px;
  overflow: hidden;
}

.horizontalSlider___281Ls {
  overflow: visible !important;
}

.company-slide {
  padding: 50px 40px;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.company-slide > h2 {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.company-slide:hover {
  background-color: white;
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  transition: 0.2s all linear;
}

.company-slide-img {
  height: 150px;
}

.company-p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #474448;
  /* filter: opacity(0); */
}

.company-slide:hover h2 {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  transition: 0.2s all linear;
}

.button-back,
.button-next {
  position: absolute;
  top: 45%;
  z-index: 10;
  font-size: 50px;
  background-color: transparent;
  border: none;
}

.button-back:hover {
  color: #8f8dff;
  left: -8.5%;
  transition: all 0.2s linear;
}

.button-next:hover {
  color: #8f8dff;
  right: -8.5%;
  transition: all 0.2s linear;
}

.button-back:focus,
.button-next:focus {
  outline: none;
}

.button-back {
  left: -8%;
}

.button-next {
  right: -8%;
}

.uni {
  width: 150px;
  margin: 0 50px;
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5);
}

.competitors {
  margin: 50px 0;
}

.company-cta {
  padding: 150px 30%;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  .tri-title {
    font-size: 25px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .company-text {
    margin-top: 25px;
  }

  .company-image {
    width: 100%;
  }

  .tri {
    flex-direction: column;
    align-items: center;
    margin: 0;
    margin-bottom: 50px;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .tri-image {
    width: 100%;
  }

  .tri-text {
    width: 100%;
  }

  .tri-title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .tri-text > h3 {
    text-align: center;
  }

  .stipulation {
    font-size: 10px;
    text-align: center;
  }

  .company-slide {
    padding: 40px 20px;
  }

  .carousel-container {
    padding: 20px;
  }

  .carousel-section > h3 {
    text-align: center;
  }

  .unis {
    flex-direction: column;
  }

  .uni {
    width: 150px;
    margin: 0;
    margin-bottom: 15px;
  }

  .company-cta {
    padding: 150px 20%;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
  .company-image {
    width: 80%;
  }
}

/* Small Phones */
@media only screen and (max-height: 570px) {
  .input {
    width: auto;
    min-width: 200px;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}

/* em {
  background: cyan;
  font-style: normal;
}

.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  color: #fff;
  margin-bottom: 1rem;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container-algo {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

body {
  font-family: sans-serif;
}
.ais-SearchBox {
  margin: 1em 0;
}
.ais-Pagination {
  margin-top: 1em;
}
.left-panel {
  float: left;
  width: 250px;
}
.right-panel {
  margin-left: 260px;
} */

