/* hex codes: */
/* purple: #8F8DFF */
/* (lighter) green: #2AF3CE */
/* (darker) green: #05D9B2 */
/* gray: #474448 */

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&family=Source+Sans+Pro:wght@300;400;700&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
}

.flex-row,
.flex-row-comp {
  display: flex;
  flex-direction: row;
}

.flex-row-comp {
  display: flex;
  flex-direction: row
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

a {
  text-decoration: none;
  display: inline-flex;
}

.logo {
  color: #474448;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 24px;
  margin-left: 10px;
}

.logo-img {
  height: 36px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in;
}

.logo-container:hover .logo-img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in;
}

.menu {
  padding: 20px 10%;
}

.menu-item {
  font-weight: bold;
  color: #474448;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.menu-item:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

.menu-item:hover:after {
  transform: scaleX(1);
}

.react-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

.Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  font-weight: bold;
  color: #474448;
  position: relative;
  display: inline-block;
}

.link:hover {
  color: #8f8dff;
  transition: all 0.2s ease-in;
}

.link:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
  transform-origin: 0% 50%;
}

.active-item:after {
  display: block;
  content: "";
  border-bottom: solid 4px #8f8dff;
  transform: scaleX(1);
}

.container-stint {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-x {
  top: 10px;
  right: 10px;
  position: absolute;
  font-size: 20px;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in;
  z-index: 10;
  color: white;
}

.modal-x:hover {
  color: #8f8dff;
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.2s ease-in;
}

.footer {
  padding: 30px 50px;
  font-size: 12px;
  background-color: #474448;
  color: white;
}

.contact-us {
  color: white;
}

.contact-us:hover {
  text-decoration: underline;
}

.button {
  background: linear-gradient(to left, #8f8dff 50.1%, #474448 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
  position: relative;
  transition: background-position 0.2s ease-in;
}

.button:hover {
  cursor: pointer;
  box-shadow: 0px 8px 8px rgba(71, 68, 72, 0.15);
  transition: all 0.2s ease-in;
  background-position: left bottom;
}

.button:focus {
  outline: 0;
}

.menu-propic-solo {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}

.menu-profile {
  margin-left: 50px;
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.menu-profile-dropdown {
  display: none;
  position: absolute;
  min-width: 150px;
  box-shadow: 0px 8px 12px rgba(71, 68, 72, 0.1);
  z-index: 1;
  right: 0;
}

/* Links inside the dropdown */
.menu-profile-dropdown > * {
  background: white;
  color: #474448;
  font-size: 14px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

/* Change color of dropdown links on hover */
.menu-profile-dropdown > *:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Show the dropdown menu on hover */
.menu-profile:hover .menu-profile-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.sign-out {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  border-top: 1px solid #f5f5f5;
  width: 100%;
  padding: 15px;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

h1 {
  font-weight: bold;
  font-size: 54px;
  margin: 10px 0;

  color: #474448;
}

.h1-smaller {
  font-weight: bold;
  font-size: 36px;
  margin: 10px 0;
  text-align: center;

  color: #474448;
}

h2 {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 26px;

  color: #8f8dff;
}

h3 {
  font-size: 24px;
  font-weight: 400;

  color: #474448;
}

h4 {
  color: white;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
}

.interstitial-text > p {
  color: #ffffff;
}

p {
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  color: #474448;
}

.subtitle {
  font-size: 12px;
  color: #474448;
}

.nobreak {
  white-space: nowrap;
}

.nobreak-comp {
  white-space: nowrap;
}

.input {
  font-family: "IBM Plex Mono", monospace;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  height: 36px;
  font-size: 14px;
  width: 300px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.input:focus {
  /* border-bottom: 4px solid #8F8DFF; */
  background-color: #f2f2ff;
  transition: all 0.2s ease-in;
}

.dropdown {
  font-family: "IBM Plex Mono", monospace;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 0;
  height: 32px;
  font-size: 14px;
  background-color: none;
}

.about-text {
  /* height: 90vh; */
  align-items: space-between;
  justify-content: center;
  padding: 100px 20%;
}

.about-card-container {
  justify-content: space-between;
}

.about-card {
  width: 45%;
}

/* Autocomplete */

.autocomplete-container {
  position: relative;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8dff;
  opacity: 1; /* Firefox */
}

.search-box-container {
  min-width: 25px;
  max-width: 90%;
  border: none;
  border-bottom: 4px solid #8f8dff;
  border-radius: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;

  color: #8f8dff;
  background-color: transparent;

  margin: 0 4px;
  display: inline-block;

  transition: border-bottom 0.2s ease-in;
  z-index: 3;
}

.search-box-container:focus {
  /* border-bottom: 4px solid #474448; */
  background-color: #f2f2ff;
  outline: none;
  transition: border-bottom 0.2s ease-in;
}

:disabled {
  background: #b0b0b0;
  cursor: not-allowed;
}

:disabled:hover {
  background: #b0b0b0;
  cursor: not-allowed;
  box-shadow: none;
}

.hide {
  position: absolute;
  visibility: hidden;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;
  white-space: nowrap;

  color: #8f8dff;
}

.ac-dropdown {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  list-style: none;
  transition: width 0.3s;
  position: absolute;
  left: 4px;
  z-index: 10;
}

.ac-dropdown-item {
  background-color: #474448;
  color: white;
  font-weight: bold;
  padding: 10px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  width: 100%;
  z-index: 10;
}

.ac-dropdown-item:hover {
  background-color: #8f8dff;
  cursor: pointer;
  transition: 0.3s all;
}

.option-active {
  color: #8f8dff;
}

.option-active:hover {
  color: #474448;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Media Queries */

.menu-desktop {
  display: flex;
}

.menu > *:last-child {
  display: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  right: 36px;
  top: 36px;
}

.bm-burger-button > button:focus {
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #474448;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross-button > button:focus {
  outline: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* General sidebar styles */
.bm-menu {
  background: #474448;
  /* padding: 50px; */
  font-size: 18px;
}

/* Wrapper for item list */
.bm-item-list > *:first-child {
  display: flex !important;
  margin-top: 75px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  font-family: "IBM Plex Mono", monospace;
  margin: 20px 0;
  font-weight: bold;
  padding: 0px 50px;
}

.bm-item:hover {
  color: #8f8dff;
}

.bm-item:focus {
  outline: none;
}

.menu-name {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px;
  margin-top: 10px;
}

.menu-propic {
  height: 100px;
  width: 100px;
  border-radius: 50px;
}

.menu-burger-profile {
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 20px;
  border: 1px solid white;
  font-size: 12px;
  margin-top: 10px;
  min-width: 120px;
  display: flex;
  justify-content: center;
}

.menu-burger-profile:hover {
  background-color: #f5f5f5;
  color: #474448;
}

.menu-item-burger {
  color: #8f8dff;
}

.sign-out-burger {
  width: 100%;
  border-top: 1px solid #f5f5f5;
  padding: 20px;
  position: absolute;
  bottom: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.sign-out-burger:hover {
  background-color: #f5f5f5;
  color: #474448;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
}

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  h1 {
    font-size: 44px;
  }

  .h1-smaller {
    font-size: 30px;
  }

  h3 {
    font-size: 20px;
  }

  .search-box-container {
    font-size: 20px;
  }

  .hide {
    font-size: 20px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .flex-row-comp {
    flex-direction: column;
  }

  .logo {
    font-size: 20px;
    display: none;
  }

  .logo-img {
    height: 28px;
  }

  .menu-item {
    font-size: 10px;
  }

  .flex-row-comp {
    display: flex;
    flex-direction: column
  }

  h1 {
    font-size: 32px;
  }

  .h1-smaller {
    font-size: 26px;
  }

  .nobreak-comp {
    white-space: normal;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }

  .button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .search-box-container {
    font-size: 18px;
  }

  .hide {
    font-size: 18px;
  }

  .footer {
    flex-direction: column;
    padding: 30px 10%;
  }

  .footer > a {
    margin-top: 10px;
  }

  .menu-desktop {
    display: none;
  }

  .menu > *:last-child {
    display: block;
  }

  .bm-burger-button {
    right: 30px;
    top: 22px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
  .logo {
    font-size: 16px;
  }

  .logo-img {
    height: 20px;
  }

  .menu-propic {
    width: 75px;
    height: 75px;
  }

  .bm-item-list > *:first-child {
    margin-bottom: 50px !important;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}
