.profile-container {
  margin: 100px 0;
}

.profile-container > * {
  margin-bottom: 50px;
}

.profile-container > :last-child {
  margin-bottom: 0px;
}

.stint-dialogue {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 200% 100%;
  animation: gradient 2s linear infinite;
  animation-direction: alternate;

  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 50px;
  filter: opacity(1);
  transition: opacity 0.2s ease-in;
}

.stint-dialogue > * {
  color: white;
  margin: 0;
  margin-bottom: 20px;
  filter: opacity(1);
  transition: opacity 0.2s ease-in;
}

.stint-dialogue > :last-child {
  margin-bottom: 0;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.loading {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 200% 100%;
  animation: gradient 2s linear infinite;
  animation-direction: alternate;

  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 50px;

  filter: opacity(0.25);
  transition: opacity 0.2s ease-in;
}

.loading > * {
  margin: 0;
  margin-bottom: 20px;
  filter: opacity(0);
  transition: opacity 0.2s ease-in;
}

.loading > :last-child {
  margin-bottom: 0;
}

.hline {
  border: 2px solid #f5f5f5;
  width: 100%;
}

/* Student form */

.student-dialogue {
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  padding: 50px;
}

.student-dialogue > * {
  margin-bottom: 40px;
}

.student-dialogue > :last-child {
  margin-bottom: 0;
}

.student-dialogue-block {
}

.student-dialogue-block > * {
  margin: 0;
  margin-bottom: 20px;
}

.student-dialogue-block > :last-child {
  margin-bottom: 0;
}

.custom-select {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 4px solid #8f8dff;
  border-radius: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  font-weight: bold;

  color: #8f8dff;

  margin: 0 4px;
  display: inline-block;
}

.custom-select:hover {
  cursor: pointer;
}

.optional-chunk {
  filter: opacity(0.5);
  z-index: 5;
  position: relative;
}

.optional-chunk * {
  z-index: 10;
}

.optional-chunk > h3 {
  margin: 0;
}

/* Second student form */

.skills-header:hover {
  cursor: pointer;
}

.skills-header:hover .skills-header-row > h3 {
  color: #8f8dff;
}

.skills-header-row {
  display: flex;
  align-items: center;
}

.skills-header-row > * {
  margin: 0;
  margin-right: 20px;
}

.student-skill-status {
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 12px;
  padding: 5px 10px;

  color: #ffffff;
}

.skill-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.skill-container > *:last-child {
  margin-right: 0;
}

.skill {
  padding: 5px 40px;
  margin: 5px;
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill:hover {
  cursor: pointer;
  box-shadow: 0px 4px 32px rgba(71, 68, 72, 0.15);
  background-color: #e0e0e0;
  transition: background-color 0.4s linear;
}

.skill-name {
  display: inline-flex;
  z-index: 5;
}

.skill-level {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.skill:hover > .skill-name {
  visibility: hidden;
}

.skill-level,
.plus,
.minus {
  visibility: hidden;
}

.skill:hover > .skill-level,
.skill:hover > .plus,
.skill:hover > .minus {
  visibility: visible;
  z-index: 5;
}

.plus {
  position: absolute;
  right: 0px;
  padding: 5px 10px;
  z-index: 6;
  background-color: none;
}

.minus {
  position: absolute;
  left: 0px;
  padding: 5px 10px;
  z-index: 6;
  background-color: none;
}

.plus:hover,
.minus:hover {
  background-color: rgb(0, 0, 0, 0.1);
  transition: all 0.1s linear;
}

.skill-bar {
  background-color: #05d9b2;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 0;
  width: 0%;
  transition: width 0.1s ease-in;
}

#hidden-skill-level {
  visibility: hidden;
  position: absolute;
}

.upload {
  justify-content: center;
  align-self: flex-start;
  margin-top: 30px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
  align-self: flex-start;
}

[type="file"] + label {
  background: linear-gradient(to left, #8f8dff 50.1%, #474448 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "IBM Plex Mono", monospace;
  font-weight: bold;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  align-self: flex-start;
}

[type="file"]:focus + label,
[type="file"] + label:hover {
  box-shadow: 0px 8px 8px rgba(71, 68, 72, 0.15);
  transition: all 0.2s ease-in;
  background-position: left bottom;
}

[type="file"]:focus + label {
  outline: 4px solid #8f8dff;
  outline: -webkit-focus-ring-color auto 5px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
  .custom-select {
    font-size: 20px;
  }
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .custom-select {
    font-size: 18px;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}
