.thank-you-background {
  height: 100vh;
  background: linear-gradient(270deg, #8f8dff 0%, #05d9b2 100%);
  background-size: 400% 100%;
  animation: gradient 2s linear infinite;
  animation-direction: alternate;
}

.thank-you-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}

.thank-you {
  width: 40%;
  text-align: center;
}

.thank-you > * {
  color: #474448;
}

.private-policy {
  margin-top: 100px;
  margin-bottom: 200px;
}

.fof-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: -50px;
}

.fof {
  width: 40%;
  text-align: center;
}

.fof-img {
  width: 150px;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;
}

.company-text {
  margin-top: 100px;
  margin-bottom: 150px;
  margin-right: 100px;
}

.payment-container > img {
  margin-top: 75px;
}

/* Media Queries */

/* Smaller Laptops ----------- */
@media only screen and (max-width: 1200px) {
}

/* Mini Screens */
@media only screen and (max-height: 1024px) {
}

/* Large Phones */
@media only screen and (max-height: 950px) and (max-width: 800px) {
  .fof,
  .thank-you {
    width: 65%;
    text-align: center;
  }

  .fof-img {
    width: 100px;
  }

  .payment-container {
    flex-direction: column-reverse;
  }

  .payment-container > img {
    margin-top: 20px;
    width: 90%;
  }

  .company-text {
    width: 100%;
  }
}

/* iPhone 6 */
@media only screen and (max-height: 670px) {
}

/* Small Phones */
@media only screen and (max-height: 570px) {
}

/* Smaller Phones */
@media only screen and (max-width: 320px) {
}
